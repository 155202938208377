







































































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {submissionModule} from '@/store/modules';
import {SubmissionResource} from '@/interfaces';
import {IResult, UAParser} from 'ua-parser-js';
import CreatedModifiedCell from '@/components/ui/CreatedModifiedCell.vue';

@Component({
  components: {CreatedModifiedCell},
})
export default class SubmissionDetail extends Vue {
  @Prop() private submission!: SubmissionResource;

  get busy(): boolean {
    return submissionModule.busy;
  }

  get hasUtmParameters(): boolean {
    const requestUrl = this.submission.attributes.requestUrl;
    if (!requestUrl) {
      return false;
    }
    return requestUrl.split('?').length > 1;
  }

  get utmParameters() {
    const requestUrl = this.submission.attributes.requestUrl;
    if (!requestUrl) {
      return [];
    }
    console.log(requestUrl);
    const [base, params] = requestUrl.split('?');
    console.log(params);
    return new URLSearchParams(params);
  }

  get parsedUserAgent(): IResult {
    const parser = new UAParser();
    parser.setUA(String(this.submission?.attributes.userAgent));
    return parser.getResult();
  }

  // async created(): Promise<void> {
  //   if (!submissionModule.find(this.submissionId)) {
  //     await submissionModule.loadById(this.submissionId);
  //   }
  // }
}
