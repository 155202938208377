



































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import {AttachmentResource, SubmissionResponseResource} from '@/interfaces';
import {Prop} from 'vue-property-decorator';
import {isArray} from 'lodash';
import {QuestionType} from '@/enums';
import {parseISO, format} from 'date-fns';
import {mdiArrowRightBoldOutline} from '@mdi/js';
import {attachmentModule} from '@/store/modules';
import Spinner from '@/components/ui/Spinner.vue';
@Component({
  components: {Spinner},
})
export default class SubmissionData extends Vue {
  @Prop({default: []})
  private submissionResponses!: SubmissionResponseResource[];
  private headers = [
    {text: 'Field', value: 'fieldLabelName'},
    {text: 'Value', value: 'value'},
  ];
  private isArray = isArray;
  private questionType = QuestionType;
  private format = format;
  private parseISO = parseISO;
  private manageApiUrl = process.env.VUE_APP_MANAGE_API_BASE_URL;
  private metaLoaded = false;
  private iconArrow = mdiArrowRightBoldOutline;

  public async mounted(): Promise<void> {
    // load meta data for all files in this submission set
    const fileFields = this.submissionResponses.filter(
      response =>
        response.attributes.surveyField.type === String(QuestionType.File)
    );
    await Promise.all(
      fileFields.map(response =>
        attachmentModule.loadById(response.attributes.fieldValue)
      )
    );
    this.metaLoaded = true;
  }

  /**
   * Attempt to parse JSON
   * On failure just return original string because that means it wasn't JSON encoded
   * @param str
   * @private
   */
  public parseJson(str: string) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return str;
    }
  }

  getItemValueLabel(responseId: string, itemValue: string): string {
    const response = this.submissionResponses.filter(
      response => response.id === responseId
    );
    if (response.length !== 1) {
      throw Error('Invalid responseId');
    }

    const item = response[0].attributes.items.filter(
      item => item.value === itemValue
    );
    if (item.length === 0) {
      //throw Error('Invalid item value');
      return 'Invalid item value';
    }

    return item[0].label;
  }

  getFileMeta(uuid: string): AttachmentResource {
    const meta = attachmentModule.find(uuid);
    if (meta) {
      return meta;
    }
    throw Error(`Meta data for ${uuid} not loaded`);
  }
}
