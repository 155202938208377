var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v("Submission Data")]),_c('v-data-table',{attrs:{"items":_vm.submissionResponses,"headers":_vm.headers,"items-per-page":100},scopedSlots:_vm._u([{key:"item.fieldLabelName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.attributes.surveyField.label)+" "),_c('small',[_c('code',[_vm._v(_vm._s(item.attributes.fieldName))])])]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [(_vm.isArray(_vm.parseJson(item.attributes.fieldValue)))?_c('ul',_vm._l((_vm.parseJson(item.attributes.fieldValue)),function(i){return _c('li',{key:i},[_vm._v(" "+_vm._s(_vm.getItemValueLabel(item.id, i))+" ")])}),0):(
          item.attributes.surveyField.type === _vm.questionType.RadioGroup ||
            item.attributes.surveyField.type === _vm.questionType.Dropdown
        )?_c('span',[_vm._v(" "+_vm._s(_vm.getItemValueLabel(item.id, item.attributes.fieldValue))+" ")]):(
          item.attributes.surveyField.type === _vm.questionType.DropdownChain
        )?_c('div',{staticClass:"align-content-center"},[_c('div',[_vm._v(" "+_vm._s(item.attributes.chain.primary.label)+" "),_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.iconArrow))]),_vm._v(" "+_vm._s(item.attributes.chain.secondary.label)),_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.iconArrow))]),_vm._v(" "+_vm._s(item.attributes.chain.child.label)+" ")],1)]):(item.attributes.surveyField.type === _vm.questionType.File)?_c('div',[(!_vm.metaLoaded)?_c('Spinner'):_vm._e(),(_vm.metaLoaded)?_c('div',[_c('p',[_c('strong',[_vm._v("File Name:")]),_vm._v(" "+_vm._s(_vm.getFileMeta(item.attributes.fieldValue).attributes.name)),_c('br'),_c('strong',[_vm._v("Content Type:")]),_vm._v(" "+_vm._s(_vm.getFileMeta(item.attributes.fieldValue).attributes.contentType)+" ")]),(
              _vm.getFileMeta(
                item.attributes.fieldValue
              ).attributes.contentType.substr(0, 5) === 'image'
            )?_c('v-img',{staticClass:"my-4",attrs:{"src":(_vm.manageApiUrl + "/attachments/" + (item.attributes.fieldValue)),"contain":"","max-height":"200"}}):_vm._e(),_c('p',[_c('v-btn',{attrs:{"href":(_vm.manageApiUrl + "/attachments/" + (item.attributes.fieldValue)),"target":"_blank","small":"","outlined":"","color":"primary"}},[_vm._v("Download")])],1)],1):_vm._e()],1):(item.attributes.surveyField.type === _vm.questionType.Checkbox)?_c('span',[_vm._v(" "+_vm._s(_vm.parseJson(item.attributes.fieldValue) ? 'Yes' : 'No')+" ")]):(item.attributes.surveyField.type === _vm.questionType.Address)?_c('span',[_c('strong',[_vm._v("Street: ")]),_vm._v(_vm._s(_vm.parseJson(item.attributes.fieldValue).street)),_c('br'),_c('strong',[_vm._v("City: ")]),_vm._v(_vm._s(_vm.parseJson(item.attributes.fieldValue).city)),_c('br'),_c('strong',[_vm._v("Province: ")]),_vm._v(_vm._s(_vm.parseJson(item.attributes.fieldValue).province)),_c('br'),_c('strong',[_vm._v("Country: ")]),_vm._v(_vm._s(_vm.parseJson(item.attributes.fieldValue).country)),_c('br'),_c('strong',[_vm._v("Postal code: ")]),_vm._v(_vm._s(_vm.parseJson(item.attributes.fieldValue).postalCode)),_c('br')]):(
          item.attributes.surveyField.type === _vm.questionType.Date &&
            item.attributes.fieldValue
        )?_c('span',[_vm._v(" "+_vm._s(_vm.format( _vm.parseISO(_vm.parseJson(item.attributes.fieldValue)), 'EEE, MMM d, yyyy' ))+" ")]):(
          item.attributes.surveyField.type === _vm.questionType.DateTime &&
            item.attributes.fieldValue
        )?_c('span',[_vm._v(_vm._s(_vm.format( _vm.parseISO(_vm.parseJson(item.attributes.fieldValue)), 'EEE, MMM d, yyyy h:mm aaa' ))+" ")]):_c('span',[_vm._v(" "+_vm._s(item.attributes.fieldValue)+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }