






















































import Vue from 'vue';
import Component from 'vue-class-component';
import {SubmissionResource, SubmissionResponseResource} from '@/interfaces';
import {Prop} from 'vue-property-decorator';
import DownloadCsv from 'vue-json-csv';
import {submissionApi} from '@/api';
import {sleep} from '@/utility';
import {ResourceType} from '@/enums';

@Component({components: {DownloadCsv}})
export default class SubmissionActions extends Vue {
  @Prop({default: []})
  private submissionResponses!: SubmissionResponseResource[];
  @Prop() private submission!: SubmissionResource;
  private loading: string | null = null;

  get responsesFlat() {
    return this.submissionResponses.map(submission => ({
      name: submission.attributes.fieldName,
      question: submission.attributes.surveyField.label,
      value: submission.attributes.fieldValue,
    }));
  }

  async moveToBucket(bucket: string) {
    this.loading = bucket;
    if (process.env.NODE_ENV === 'development') {
      await sleep(2000);
    }
    await submissionApi.postActions({
      type: ResourceType.Action,
      attributes: {
        action: 'move',
        destinationBucket: bucket,
        submissionIds: [this.submission.id],
      },
    });
    this.loading = null;
    this.submission.attributes.bucket = bucket;
  }
}
